#loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  z-index: 1000;
  top: 0;
  left: 0;
}

#loader #loader-body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#loader #loader-body .logo-div {
  background-color: white;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  animation: changeDim 1.5s linear infinite;
}

#loader #loader-body .logo-div div {
  position: absolute;
  width: 10px;
  background-color: red;
  height: 200px;
  animation: pulse 1.5s linear infinite;
}

#loader #loader-body .logo-div img {
  position: absolute;
  z-index: 1;
  animation: changeHeight 1.5s linear infinite;
}

@keyframes changeHeight {
  0% {
    height: 150px;
  }
  50% {
    height: 200px;
  }
  100% {
    height: 150px;
  }
}

@keyframes changeDim {
  0% {
    width: 150px;
    height: 150px;
  }
  50% {
    width: 200px;
    height: 200px;
  }
  100% {
    width: 150px;
    height: 150px;
  }
}

@keyframes pulse {
  0% {
    left: -20px;
  }
  100% {
    left: 200px;
  }
}
